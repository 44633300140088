import { ProductVariantModel } from '~/models/productVariant';

export type StockLabel = {
  text: string,
  textShort: string,
  color: string,
  type: 'preorder' | 'ondemand' | 'expired' | 'tempSoldout' | 'soldout' | 'active' | ''
}

export default function useStockStatus() {
  const { $t } = useNuxtApp();

  const getStockLabel = (
    variant: ProductVariantModel,
    detailedInstock = false,
    alternativeText = false // category uses alternative texts
  ): StockLabel => {
    if (variant.isPreOrderProduct) {
      return {
        text: $t('stock.releaseDate', { date : variant.incomingDate }),
        textShort: $t('stock.releaseDateWithLineBreak'),
        color: 'successPrimary',
        type: 'preorder',
      };
    } else if (variant.isOnDemandProduct) {
      const anyInStock = variant.stockStatuses?.some((s) => s.inStock > 0);
      // If there are any in stock, this overrides "on demand"
      if (anyInStock) {
        return {
          text: $t('stock.inStockSendWithinDays'),
          textShort: $t('stock.inStock'),
          color: 'successPrimary',
          type: 'active',
        };
      } else {
        return {
          text: $t('stock.isOnDemandProduct', { num: variant.stockStatus?.leadTimeDayCount }),
          textShort: $t('stock.isOnDemandProductWithLineBreak'),
          color: 'attentionPrimary',
          type: 'ondemand',
        };
      }
    } else if (variant.productHasExpired) {
      return {
        text: $t('stock.productHasExpired'),
        textShort: $t('stock.productHasExpired'),
        color: 'criticalDark',
        type: 'expired',
      };
    } else if (variant.isTempSoldOut) {
      return {
        text: $t('stock.tempSoldout', { date : variant.incomingDate }),
        textShort: $t('stock.tempSoldout', { date : variant.incomingDate }),
        color: 'attentionPrimary',
        type: 'tempSoldout',
      };
    } else if (variant.isActive && variant.stockValue > 1) {
      let text = $t('stock.inStockSendWithinDays');
      let color = 'brandPrimary';
      if (detailedInstock && !alternativeText) {
        if (variant.stockValue >= 100) {
          text = $t('stock.std.over100');
        }
        else if (variant.stockValue >= 50) {
          text = $t('stock.std.over50');
        }
        else if (variant.stockValue >= 25) {
          text = $t('stock.std.over25');
        }
        else {
          text = $t('stock.std.less25');
          color = 'attentionPrimary';
        }
      }
      if (detailedInstock && alternativeText) {
        if (variant.stockValue >= 5) {
          text = $t('stock.alt.over5');
        }
        else {
          text = $t('stock.alt.less5');
          color = 'attentionPrimary';
        }
      }
      //text += variant.stockValue;
      return {
        text,
        textShort: $t('stock.inStock'),
        color,
        type: 'active',
      };
    } else if (variant.isActive && variant.isSoldOut && !variant.hasIncomingDate) {
      return {
        text: $t('stock.soldout'),
        textShort: $t('stock.soldoutShort'),
        color: 'criticalDark',
        type: 'soldout',
      };
    }
    return { text: '', textShort: '', color: '', type: '' };
  };

  return {
    getStockLabel,
  };
}
