<template>
  <div
    class="StockStatus w-[5px] h-[5px] rounded-full mr-6 relative -top-1"
    :class="`bg-${stockLabel.color}`"
  />
  <span
    class="type-sm"
    :class="{
      'whitespace-nowrap': useShortLabels
    }"
    v-html="useShortLabels ? stockLabel.textShort : stockLabel.text"
  />
</template>

<script setup lang="ts">
import { ProductVariantModel } from '~/models/productVariant';
import useStockStatus, { StockLabel } from '~/composeables/useStockStatus';
import { useGlobalContentStore } from '~/store/globalContent';
import { ProductModel } from '~/models/product';
const globalContent = useGlobalContentStore();

interface Props {
  variant: ProductVariantModel;
  productItem: ProductModel
  useShortLabels?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  useShortLabels: false,
});

const { getStockLabel } = useStockStatus();

const alternativeText = computed(()=> {
  const hasCodes = props.productItem.categories.map(m => m.code);
  return globalContent.getAltDeliveryTextCategories.filter(f => hasCodes.includes(f)).length > 0;
});

const stockLabel = computed<StockLabel>(() => {
  return getStockLabel(props.variant, true, alternativeText.value);
});

</script>
