<template>
  <div
    v-if="userStore.isLoggedIn && hasCampaign"
    class="MultiBuyDiscount mb-16 relative"
  >
    <div v-if="userPrice.status === 'ok'">
      <div
        v-if="userPrice.price?.priceBeforeDiscountBeforeVat !== userPrice.price?.priceBeforeVat && !giftCampaigns.length"
      >
        <div class="type-sm-medium uppercase mb-12">
          {{ $t('buyMore.multiCampaign-selectionHeadline') }}
        </div>
        <div
          class="p-16 border mb-12 cursor-pointer"
          :class="{
            'bg-lighter border-transparent': !priceListLocked,
            'bg-lightest border-darkest': priceListLocked
          }"
          @click="changeCampaignType(true)"
        >
          <div class="flex justify-between items-center mb-12 type-sm-medium">
            <div class="flex items-center">
              <div class="w-20 h-20 rounded-full border border-darkest flex items-center justify-center mr-8">
                <div v-if="priceListLocked" class="w-12 h-12 rounded-full bg-darkest" />
              </div>
              <div>
                {{ giftCampaigns.length ? $t('buyMore.gift-headline') : $t('buyMore.multiCampaign-headline') }}
              </div>
            </div>
            <div
              class="type-headline-lg"
              :class="{
                'text-criticalDark': stepCampaigns.length || percentageCampaigns.length,
              }"
            >
              {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
            </div>
          </div>
          <div v-for="(percentageCampaign, index) in percentageCampaigns" :key="index">
            <div
              v-if="percentageCampaign"
              class="flex justify-between items-center
                p-12 mb-2
                type-xs-medium leading-single"
              :class="{
                'bg-[#fef3f6]': !percentageCampaign.reached,
                'bg-[#eaffe7]': percentageCampaign.reached,
              }"
            >
              <div class="flex">
                <div class="basis-[14px] grow-0 shrink-0 self-start relative">
                  <img :src="percentageCampaign.reached ? '/icons/check-filled.svg': '/icons/sale.svg'" class="w-[14px] relative -top-2">
                </div>
                <div
                  class="basis-full pl-8"
                  :class="{
                    'text-criticalDark': !percentageCampaign.reached,
                    'text-[#559450]': percentageCampaign.reached
                  }"
                >
                  {{ percentageCampaign.text }}
                </div>
                <div v-if="percentageCampaigns.length === 1" class="text-darker mt-2 empty:hidden">
                  {{ percentageCampaign.description }}
                </div>
              </div>
              <div
                class="pl-8 whitespace-nowrap"
                :class="{
                  'text-[#559450]': percentageCampaign.reached,
                  'text-criticalDark': !percentageCampaign.reached,
                }"
              >
                {{ percentageCampaign.savings }}
              </div>
            </div>
          </div>

          <div v-if="stepCampaigns">
            <div
              v-for="(step, index) in stepCampaigns"
              :key="index"
              class="flex justify-between items-center
                     p-12 mb-2
                     type-xs-medium leading-single"
              :class="{
                'bg-[#fef3f6]': !step.reached,
                'bg-[#eaffe7]': step.reached,
              }"
            >
              <div class="basis-[14px] grow-0 shrink-0 self-start relative">
                <img :src="step.reached ? '/icons/check-filled.svg': '/icons/sale.svg'" class="w-[14px] relative -top-2">
              </div>
              <div class="basis-full pl-8">
                <div>{{ step.text }}</div>
                <div v-if="step.reached && stepCampaigns.length === 1" class="mt-2 empty:hidden">{{ step.description }}</div>
              </div>
              <div
                class="pl-8 whitespace-nowrap"
                :class="{
                  'text-[#559450]': step.reached,
                  'text-criticalDark': !step.reached,
                }"
              >
                {{ step.savings }}
              </div>
            </div>
          </div>
          <div v-if="giftCampaigns.length">
            <div
              v-for="(gift, index) in giftCampaigns"
              :key="index"
              class="flex justify-between items-center
                 p-12 mb-2 bg-[#fef3f6]
                 type-xs-medium leading-single"
            >
              <div class="basis-[14px] grow-0 shrink-0 self-start relative">
                <img src="/icons/sale.svg" class="w-[14px] relative -top-2">
              </div>
              <div class="basis-full pl-8 text-criticalDark">
                <div>{{ gift.text }}</div>
                <div v-if="gift.description" class="text-darker mt-2 empty:hidden">{{ gift.description }}</div>
              </div>
            </div>
          </div>
          <div v-if="stepCampaigns.length > 1 || percentageCampaigns.length > 1 || giftCampaigns.length > 1" class="flex justify-center type-xs-medium text-dark mt-12">
            {{ $t('buyMore.multiCampaign-description') }}
          </div>
        </div>
        <div
          class="p-16 bg-lighter border cursor-pointer"
          :class="{
            'bg-lighter border-transparent': priceListLocked,
            'bg-lightest border-darkest': !priceListLocked,
          }"
          @click="changeCampaignType(false)"
        >
          <div class="flex justify-between type-sm-medium">
            <div class="flex">
              <div class="w-20 h-20 rounded-full border border-darkest flex items-center justify-center mr-8 mt-4 bg-lightest">
                <div v-if="!priceListLocked" class="w-12 h-12 rounded-full bg-darkest" />
              </div>
              <div>
                <div>
                  {{ $t('buyMore.unique-headline') }}
                </div>
                <div class="type-xs">
                  Ord pris {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }} (din rabatt <span class="text-criticalDark">{{ userPrice.price.discountPercentage }}%</span>)
                </div>
              </div>
            </div>
            <div class="type-headline-lg text-criticalDark">
              {{ userPrice.price?.priceBeforeVatDisplay }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(percentageCampaign, index) in percentageCampaigns" :key="index">
          <div
            v-if="percentageCampaign"
            class="flex justify-between items-center
              p-12 mb-2
              type-xs-medium leading-single"
            :class="{
              'bg-[#fef3f6]': !percentageCampaign.reached,
              'bg-[#eaffe7]': percentageCampaign.reached,
            }"
          >
            <div>
              <div
                :class="{
                  'text-criticalDark': !percentageCampaign.reached,
                  'text-[#559450]': percentageCampaign.reached
                }"
              >
                {{ percentageCampaign.text }}
              </div>
              <div class="text-darker mt-2 empty:hidden">
                {{ percentageCampaign.description }}
              </div>
            </div>
            <div
              class="pl-8 whitespace-nowrap"
              :class="{
                'text-[#559450]': percentageCampaign.reached,
                'text-criticalDark': !percentageCampaign.reached,
              }"
            >
              {{ percentageCampaign.savings }}
            </div>
          </div>
        </div>

        <div v-if="stepCampaigns">
          <div
            v-for="(step, index) in stepCampaigns"
            :key="index"
            class="flex justify-between items-center
                   p-12 mb-2
                   type-xs-medium leading-single"
            :class="{
              'bg-[#fef3f6]': !step.reached,
              'bg-[#eaffe7]': step.reached,
            }"
          >
            <div class="basis-[14px] grow-0 shrink-0 self-start relative">
              <img :src="step.reached ? '/icons/check-filled.svg': '/icons/sale.svg'" class="w-[14px] relative -top-2">
            </div>
            <div class="basis-full pl-8">
              <div>{{ step.text }}</div>
              <div v-if="step.reached" class="mt-2 empty:hidden">{{ step.description }}</div>
            </div>
            <div
              class="pl-8 whitespace-nowrap"
              :class="{
                'text-[#559450]': step.reached,
                'text-criticalDark': !step.reached,
              }"
            >
              {{ step.savings }}
            </div>
          </div>
        </div>
        <div v-if="giftCampaigns">
          <div
            v-for="(gift, index) in giftCampaigns"
            :key="index"
            class="flex justify-between items-center
              p-12 mb-2 bg-[#fef3f6]
              type-xs-medium leading-single"
          >
            <div class="basis-[14px] grow-0 shrink-0 self-start relative">
              <img src="/icons/sale.svg" class="w-[14px] relative -top-2">
            </div>
            <div class="basis-full pl-8 text-criticalDark">
              <div>{{ gift.text }}</div>
              <div v-if="gift.description" class="text-darker mt-2 empty:hidden">{{ gift.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductVariantModel } from '~/models/productVariant';
import { useUserStore } from '~/store/user';
import useMultiDiscount from '~/composeables/useMultiDiscount';

const { $t } = useNuxtApp();

const userStore = useUserStore();

const props = defineProps<{
  activeVariant: ProductVariantModel & { name: string },
}>();

const emit = defineEmits<{
  (e: 'changeCampaignType', payload: boolean): void,
}>();

const priceListLocked = ref(true);

const { stepCampaigns, percentageCampaigns, giftCampaigns, hasCampaign } = useMultiDiscount(props);

const userPrice = computed(()=> {
  return userStore.getPrice(props.activeVariant.partNo, false);
});

const changeCampaignType = (type: boolean) => {
  priceListLocked.value = type;
  emit('changeCampaignType', type);
};

</script>
